import { PERMISSIONS } from "@/consts/permissions"
import LocalIcon from "@/components/LocalIcon"
import React from "react"
import { AccordionItem, AccordionTrigger } from "@radix-ui/react-accordion"
import { Accordion, AccordionContent } from "./ui/accordion"
import useHasPermissions from "@/hooks/useHasPermissions"
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from "./ui/sheet"
import Logo from "./Logo"
import { Button } from "./ui/button"

function SidebarButtons({ current, currentSubDomain, burguerButton = false }) {
  const permissions = useHasPermissions().permissions

  const links = {
    dashboard: {
      permission: PERMISSIONS.read_user,
      name: "Dashboard",
      href: ".",
      icon: "clarity:home-line",
    },
    orders: {
      permission: PERMISSIONS.read_repair_order,
      name: "Ordenes",
      href: "/orders",
      icon: "clarity:list-line",
      dropdown: [
        {
          permission: PERMISSIONS.read_repair_order,
          href: "/received",
          name: "Ingresadas",
        },
        {
          permission: [
            PERMISSIONS.create_technician_report,
            PERMISSIONS.create_supplier_report,
            PERMISSIONS.read_repair_order,
          ],
          href: "/reviewing",
          name: "En Revisión",
        },
        {
          permission: PERMISSIONS.read_repair_order,
          href: "/inConfirmation",
          name: "En Confirmación",
        },
        {
          permission: [
            PERMISSIONS.create_technician_report,
            PERMISSIONS.create_supplier_report,
            PERMISSIONS.read_repair_order,
          ],
          href: "/confirmed",
          name: "Confirmadas",
        },
        {
          permission: [
            PERMISSIONS.create_technician_report,
            PERMISSIONS.create_supplier_report,
            PERMISSIONS.read_repair_order,
          ],
          href: "/rejected",
          name: "Rechazadas",
        },
        {
          permission: [PERMISSIONS.read_repair_order],
          href: "/repaired",
          name: "Reparadas",
        },
      ],
    },
    clients: {
      permission: PERMISSIONS.read_client,
      name: "Clientes",
      href: "/clients",
      icon: "clarity:users-line",
    },
    suppliers: {
      permission: PERMISSIONS.read_supplier,
      name: "Proveedores",
      href: "/suppliers",
      icon: "clarity:truck-line",
    },
    technicians: {
      permission: PERMISSIONS.read_technician,
      name: "Tecnicos",
      href: "/technicians",
      icon: "clarity:users-line",
    },
    users: {
      permission: PERMISSIONS.read_user,
      name: "Usuarios",
      href: "/users",
      icon: "clarity:users-line",
    },
    staffs: {
      permission: PERMISSIONS.read_staff,
      name: "Personal",
      href: "/staffs",
      icon: "clarity:users-line",
    },
    roles: {
      permission: PERMISSIONS.read_role,
      name: "Roles",
      href: "/roles",
      icon: "clarity:administrator-line",
    },
  }

  if (burguerButton)
    return (
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" className="border-border">
            <LocalIcon
              icon="clarity:menu-line"
              className="dark:text-white text-xl"
            />
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <SheetHeader>
            <Logo className="justify-center h-full pb-6" />
          </SheetHeader>
          {Object.entries(links).map(([key, item]) =>
            item.dropdown ? (
              <DropdownLink
                key={key}
                {...item}
                hasMainLinkPermission={hasPermission(
                  permissions,
                  item.permission
                )}
                isCurrent={current === key}
                currentSubDomain={currentSubDomain}
              />
            ) : (
              hasPermission(permissions, item.permission) && (
                <NormalLink key={key} {...item} isCurrent={current === key} />
              )
            )
          )}
        </SheetContent>
      </Sheet>
    )

  return (
    <>
      {Object.entries(links).map(([key, item]) =>
        item.dropdown ? (
          <DropdownLink
            key={key}
            {...item}
            // hasMainLinkPermission={hasPermission(permissions, item.permission)}
            isCurrent={current === key}
            currentSubDomain={currentSubDomain}
          />
        ) : (
          hasPermission(permissions, item.permission) && (
            <NormalLink key={key} {...item} isCurrent={current === key} />
          )
        )
      )}
    </>
  )
}
export { SidebarButtons }

function NormalLink({
  href,
  icon,
  name,
  isCurrent,
  notNavigate = false,
  rightIcon,
}) {
  if (notNavigate)
    return (
      <p className={linkStyles(isCurrent)}>
        {icon && <LocalIcon icon={icon} className="text-xl" />} {name}
        {rightIcon && <LocalIcon icon={rightIcon} className="text-l" />}
      </p>
    )

  return (
    <a className={linkStyles(isCurrent)} href={href}>
      {icon && <LocalIcon icon={icon} className="text-xl" />} {name}
      {rightIcon && <LocalIcon icon={rightIcon} className="text-l" />}
    </a>
  )
}

function DropdownLink({
  href: path,
  icon,
  name,
  //hasMainLinkPermission,
  dropdown,
  isCurrent,
  currentSubDomain,
}) {
  const isInSubDomain = dropdown.some(({ href }) => currentSubDomain === href)
  const [show, setShow] = React.useState(isInSubDomain)
  const [hoverTimeout, setHoverTimeout] = React.useState(null)
  const permissions = useHasPermissions().permissions

  function showItems() {
    if (hoverTimeout) clearTimeout(hoverTimeout)
    setHoverTimeout(
      setTimeout(() => {
        setShow(true)
      }, 50)
    )
  }

  function hideItems() {
    if (isInSubDomain) return
    if (hoverTimeout) clearTimeout(hoverTimeout)

    setHoverTimeout(
      setTimeout(() => {
        setShow(false)
      }, 50)
    )
  }

  return (
    <Accordion type="single" value={show} collapsible>
      <AccordionItem value={true}>
        <AccordionTrigger
          className="w-full"
          // onMouseEnter={showItems}
          // onMouseLeave={hideItems}
          onClick={() => setShow(val => !val)}>
          <NormalLink
            href={path}
            icon={icon}
            name={name}
            //notNavigate={!hasMainLinkPermission}
            notNavigate
            isCurrent={isCurrent}
            rightIcon={"oui:arrow-down"}
          />
        </AccordionTrigger>
        <AccordionContent
          className="relative mt-1 p-0 ml-4"
          // onMouseEnter={showItems}
          // onMouseLeave={hideItems}
        >
          <div className="border-l-4 rounded-xl h-full absolute" />
          {dropdown.map(
            ({ href, name, permission }) =>
              hasPermission(permissions, permission) && (
                <a
                  key={href}
                  href={path + href}
                  className={linkStyles(currentSubDomain === href, " m-2 p-1")}>
                  {name}
                </a>
              )
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

function linkStyles(isCurrent, extraClasses = "") {
  return `rounded-md p-2 hover:bg-accent flex gap-2 text-nowrap dark:text-white text-ellipsis items-center ${extraClasses} ${
    isCurrent ? "bg-accent" : ""
  }`
}

function hasPermission(permissions, permission) {
  return Array.isArray(permission)
    ? permission.some(p => permissions.includes(p))
    : permissions.includes(permission)
}
